<template>
  <span v-if="temBias.length > 0">
    <span v-if="measurement"> {{ $t('components.titles.fromSupervisor') }}</span>
    <span v-else> {{ $t('components.titles.fromMedian') }}</span>
  </span>
</template>

<script>
import { mapState } from 'vuex';
import { shouldUseSupervisorTEM } from '../../../../../util/standardization';

export default {
  name: 'BiasMeasurement',
  props: {
    temBias: {
      type: Array,
      required: true
    },
    property: {
      type: String,
      required: true
    }
  },
  computed: {
    measurement() {
      const metricResult = this.temBias
        .find(result => !result.is_supervisor && result.bias_relative_to && result.metric.toUpperCase() === this.property.toUpperCase());

      if (metricResult) {
        return shouldUseSupervisorTEM(metricResult);
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
